enum ImageType {
  Original = 'original',
  Thumbnail144p = 'thumbnail_144p',
  Thumbnail240p = 'thumbnail_240p',
  Thumbnail360p = 'thumbnail_360p',
  Thumbnail480p = 'thumbnail_480p',
  Thumbnail720p = 'thumbnail_720p',
  Thumbnail1080p = 'thumbnail_1080p',
  Thumbnail1440p = 'thumbnail_1440p',
  Thumbnail2160p = 'thumbnail_2160p'
}

export default ImageType
